import {ExtensibleEntityDto} from './ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface CoachDto extends ExtensibleEntityDto <Guid> {
  name: string
  phone: string
  idType: number
  idNumber: string
  birthday: string
  idCard1: string
  idCard2: string
  location: string
  faceImg: string
  sex: number
  workingYears: number
  entryCertificate: string
  qualifications: string
  understandCanal: number
  state: number
  remark: string
  reason: string
}

export class CreateOrUpdateCoachDto {
  public name: string = ''
  public phone: string = ''
  public idType: number | undefined
  public idNumber: string = ''
  public birthday: string = ''
  public idCard1: string = ''
  public idCard2: string = ''
  public location: string = ''
  public faceImg: string = ''
  public sex: number | undefined
  public workingYears: number | undefined
  public entryCertificate: string = ''
  public qualifications: string = ''
  public understandCanal: number | undefined
  public state: number | undefined
  public remark: string = ''
  public reason: string = ''
}

export class GetListCoachDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
}
