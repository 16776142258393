









































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {CoachDto, CreateOrUpdateCoachDto, GetListCoachDto} from '@/framework/models/Coach';
import CoachApi from '@/api/course/coach';
import {upload} from '@/api/file/upload';

@Component({})
export default class CoachIndex extends Vue {
  private api = new CoachApi();
  private listQuery = new GetListCoachDto();
  private tableData: PageResultDto<CoachDto> = {
    items: new Array<CoachDto>(),
    totalCount: 0
  };

  private srcList = new Array()

  private upload = {
    action: '',
    imageUrl: ''
  };

  private uploadData: any | undefined;

  private dialog = {
    title: '',
    visible: false
  };
  private dataForm: CreateOrUpdateCoachDto = {
    birthday: '',
    entryCertificate: '',
    faceImg: '',
    idCard1: '',
    idCard2: '',
    idNumber: '',
    idType: undefined,
    location: '',
    name: '',
    phone: '',
    qualifications: '',
    remark: '',
    sex: undefined,
    state: undefined,
    understandCanal: undefined,
    workingYears: undefined,
    reason: ''
  };

  private rules = {
    name: [{
      required: true,
      message: '名称不能为空',
      trigger: 'blur'
    }],
    phone: [{
      required: true,
      message: '手机号不能为空',
      trigger: 'blur'
    }],
    sex: [{
      required: true,
      message: '性别不能为空',
      trigger: 'blur'
    }],
    workingYears: [{
      required: true,
      message: '工作年限不能为空',
      trigger: 'blur'
    }],
  };

  private pagination = {
    currentPage: 1
  }

  private async activated() {
    await this.getList();
  }

  private async currentChange(currentPage: number) {
    this.pagination.currentPage = currentPage
    this.listQuery.skipCount = (currentPage - 1) * this.listQuery.maxResultCount
    await this.getList()
  }

  private handleFaceAvatarSuccess(file: any) {
    this.upload.imageUrl = URL.createObjectURL(file.raw);
    this.uploadData = new FormData();
    this.uploadData.append('file', file.raw);
  }


  private beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      this.$message.error('图片只能是 JPG|PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
  }


  private uploadHttpRequest() {
    upload(this.uploadData).then((res) => {
      if (res) {
        this.dataForm.faceImg = res.fullName;
        this.handelSave();
      }
    });
  }


  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private handleCreate() {
    this.resetForm();
    this.srcList = []
    this.dialog.title = '新增';
    this.dialog.visible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private updateHandle(row: any) {
    this.resetForm();
    this.srcList = []
    this.dialog.visible = true;
    this.dialog.title = '编辑';
    this.dataForm = Object.assign({}, row);
    if (row.faceImg) {
      this.upload.imageUrl = `${row.faceImg}`;
    }

    if (row.idCard1) {
      this.srcList.push(`${row.idCard1}`)
    }

    if (row.idCard2) {
      this.srcList.push(`${row.idCard2}`)
    }

    if (row.entryCertificate) {
      this.srcList.push(`${row.entryCertificate}`)
    }

    if (row.qualifications) {
      this.srcList.push(`${row.qualifications}`)
    }

    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.dataForm = {
      birthday: '',
      entryCertificate: '',
      idCard1: '',
      idCard2: '',
      idNumber: '',
      idType: undefined,
      location: '',
      qualifications: '',
      understandCanal: undefined,
      name: '',
      phone: '',
      faceImg: '',
      sex: 1,
      workingYears: undefined,
      state: 1,
      remark: '',
      reason: ''
    };
  }

  private handelConfirm() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }
      if (this.uploadData) {
        this.uploadHttpRequest();
      } else {
        await this.handelSave();
      }
    });
  }

  private async handelSave() {
    if (this.dialog.title === '新增') {
      await this.creating(this.dataForm);
    } else if (this.dialog.title === '编辑') {
      await this.editing(this.dataForm);
    }
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.close();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.close();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private close() {
    this.dialog.visible = false;
  }

};
